import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AddressifyAddressInfo } from '../interfaces/address-info';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AddressifyService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
   *Returns an array of complete address strings for the given incomplete address string. 
    Unit/Level/Street number information is piped through from the user input. 
    To include this information and to only return addresses where the 
    Unit/Level/Street number exists please refer to the addresspro/autocomplete endpoint.
   * @param term
   * @returns array of complete address strings for the given incomplete address string
   */
  autoComplete(term: string): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${environment.addressify.baseUrl}/addresspro/autocomplete?api_key=${environment.addressify.apiKey}&term=${term}`,
      )
      .pipe(
        map((x) => {
          console.log(`addressify autocomplete ${term} `);
          return x;
        }),
        catchError(this.handleError<string[]>('autocomplete', [])),
      );
  }

  /**
   * Parses the given address into it's individual address fields.
   * @param term
   * @returns AddressifyParsedAddress
   */
  info(term: string): Observable<AddressifyAddressInfo> {
    return this.http
      .get<AddressifyAddressInfo>(
        `${environment.addressify.baseUrl}/addresspro/info?api_key=${environment.addressify.apiKey}&term=${term}`,
      )
      .pipe(
        map((x) => {
          console.log(`addressify parse ${term} `);
          return x;
        }),
        catchError(this.handleError<AddressifyAddressInfo>('parse')),
      );
  }
  /**
   * Gets the applicable states by the given postCode.
   * @param postCode
   * @returns string[]
   */
  statesForPostCode(postCode: string): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${environment.addressify.baseUrl}/address/statesForPostcode?api_key=${environment.addressify.apiKey}&term=${postCode}`,
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError(this.handleError<string[]>('statesForPostCode')),
      );
  }
}
