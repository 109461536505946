<section class="footer" [ngClass]="dynamicClassName">
  <div class="container-xl footer-top mb-4">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <ul class="ft-links">
        <li>
          <a target="_blank" routerLink="/terms-and-conditions">Terms and Conditions</a>
          <!-- <span class="d-none d-md-flex smallhide">|</span> -->
        </li>
        <li>
          <a target="_blank" routerLink="/privacy-policy">Privacy Policy</a>
          <!-- <span class="d-none d-md-flex smallhide">|</span> -->
        </li>
        <li>
          <a target="_blank" routerLink="/refunds">Refunds Notice</a>
          <!-- <span class="d-none d-md-flex smallhide">|</span> -->
        </li>
        <li>
          <a target="_blank" routerLink="/how-to-claim">How to Claim</a>
          <!-- <span class="d-none d-md-flex smallhide">|</span> -->
        </li>
        <li><a target="_blank" href="https://travel.raa.com.au/contact/faq/travel-insurance/" >FAQs</a></li>
      </ul>
    </div>
  </div>
  <div class="container-xl footer-bottom">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <div class="para">
        <p style="margin-bottom: 0.0001pt; text-align: left;"><span>Tokio Marine &amp; Nichido Fire Insurance Co Ltd (ABN 80 000 438 291 AFSL 246 548) is the issuer of RAA Travel Insurance. The 'RAA Travel Insurance - Combined Financial Services Guide and Product Disclosure Statement' (PDS) which includes the policy wording, is available&nbsp;<a rel="noopener noreferrer" target="_blank" href="{{ pdsLink }}"><span style="color: windowtext;">here</span></a>.&nbsp;</span>Any financial advice provided by RAA which is set out above is general in nature only, and does not take into account your objectives, financial situation or needs. Because of this, you should, before acting on the advice, consider the appropriateness of the advice, having regards to your objectives, financial situation and needs. You should consider the PDS in deciding whether to acquire, or to continue to hold the policies. Where it provides financial services in relation to RAA Travel Insurance (such as financial advice or arranging for the issue of insurance), Royal Automobile Association of South Australia (ABN 90 020 001 807 AR 228 575) is acting as an Authorised Representative of the issuer of the insurance, Tokio Marine &amp; Nichido Fire Insurance Co., Ltd. (ABN 80 000 438 291 AFSL 246 548). Whilst the PDS outlines the Terms and Conditions of these products, the TMD’s outline the intended class of customers that comprise the target market for these travel products. This will allow you to consider which products best suit your objectives, financial situation and needs and consider the products appropriateness to your personal circumstances. TMD’s also outline matters involving the distribution and the review of these products. The TMD’s for each travel product can be found in <a href="{{ tmdLink }}" target="_blank">here</a>.</p>
        </div>
    </div>
  </div>
</section>
