<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/privacy-policy.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <!-- <h1>Tokio Marine Australasia Privacy Policy</h1> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <h3>Privacy policy</h3>
      <ul>
          <li>
          <a href="https://www.tokiomarine.com.au/wp-content/uploads/2023/09/Privacy-Policy-2023.pdf">Tokio Marine &amp; Nichido Fire Insurance Co., Ltd. Privacy Policy</a>
          </li>
          <li>
          <a href="https://www.raa.com.au/privacy-policy"><span>Royal Automobile Association of South Australia Privacy Policy</span></a>
          </li>
      </ul>	</div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
