<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img src="assets/images/raa/page-header/privacy-policy.jpg" alt="" class="w-100 page-title-image" />
    <div class="page-header-title-caption">
      <h1>Review of Policy Terms</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <h3>Review of Policy Terms</h3>
            <p>
              <span style="font-size: 16px">
                ASIC has called on general insurers to review their insurance policies to ensure that they are transparent and do not contain unfair terms. Recently, ASIC notified us of some concerns about whether our cover for medical conditions was sufficiently clear to our customers and consistent with our customers' understanding of their cover. We take our responsibilities seriously and have undertaken a review of our travel insurance policies to identify areas where we can increase customer awareness and understanding of the cover we provide.
              </span>
            </p>

            <p>
              <span style="font-size: 16px">
                We have made enhancements to make our policy clearer in describing the cover that we provide for medical conditions. We encourage you to read the 'Medical Conditions' section of our Product Disclosure Statement and to ask us any questions you may have if you are unclear about whether a medical condition will be covered during your travel.
              </span>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>