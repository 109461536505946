<div
  class="offcanvas offcanvas-bottom d pricebrkdwn"
  tabindex="-1"
  id="offcanvasBottom"
  aria-labelledby="offcanvasBottomLabel"
>
  <div class="offcanvas-body medium">
    <div class="container-fluid p-2">
      <div class="row">
        <div class="col-sm-4 col-4">
          <label class="offc">Select plan</label>
          <h3>{{ selectedCover?.selectedCoverTitle }}</h3>
        </div>
        <div class="col-sm-6 col-6">
          <label class="offc">Quote Number</label>
          <h3>{{ quoteDetail?.quoteNumber }}</h3>
        </div>

        <div class="col-2 text-end">
          <a data-bs-dismiss="offcanvas" aria-label="Close"
            ><img src="assets/images/raa/cancel.png"
          /></a>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-8 col-12 withborder p-xl-2 p-xxl-4 pt-4 pb-4 ps-lg-4 pe-lg-4 ps-0 pe-0"
        >
          <div class="graybg mb-2 ps-2">
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Destination</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">{{ countryNames }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Trip Type</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  {{
                    quoteEnquiry?.tripType === 'Single'
                      ? 'Single Trip'
                      : 'Annual Multi Trip'
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Travel Dates</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  {{ quoteEnquiry?.depart | dateFormat : 'DD/MM/YYYY'
                  }}<span
                    ><img src="assets/images/raa/chevron_right.png" /></span
                  >{{ quoteEnquiry?.return | dateFormat : 'DD/MM/YYYY' }}
                  <span
                    *ngIf="quoteEnquiry?.isSingleTrip"
                    class="text-gray ms-1"
                    >{{ travelDays }} days</span
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Cruise</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <!--<span class="pe-3"
                    >14 Feb ’24<span
                      ><img src="assets/images/raa/chevron_right.png" /></span
                    >18 Feb ’24</span
                  ><span class="text-gray">4 days</span>-->
                  <span class="pe-3">{{
                    selectedCover?.selectedPrice?.isCruise === true
                      ? 'Yes'
                      : 'No'
                  }}</span>
                </p>
                <!--<p class="fw-semibold mb-1">
                  <span class="pe-3"
                    >23 Feb ’24<span
                      ><img src="assets/images/raa/chevron_right.png" /></span
                    >25 Feb ’24</span
                  ><span class="text-gray">2 days</span>
                </p>-->
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Ski/Winter</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <!--<span class="pe-3"
                    >14 Feb ’24<span
                      ><img src="assets/images/raa/chevron_right.png" /></span
                    >18 Feb ’24</span
                  ><span class="text-gray">4 days</span>-->
                  <span class="pe-3">{{
                    selectedCover?.selectedPrice?.isSki === true ? 'Yes' : 'No'
                  }}</span>
                </p>
                <!--<p class="fw-semibold mb-1">
                  <span class="pe-3"
                    >23 Feb ’24<span
                      ><img src="assets/images/raa/chevron_right.png" /></span
                    >25 Feb ’24</span
                  ><span class="text-gray">2 days</span>
                </p>-->
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-3">
                <p class="offc label mb-1">Duration</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">15 Days</p>
              </div> 
            </div>-->
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Age of Travellers</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">{{ displayTravellersAge }}</p>
              </div>
            </div>
            <div class="row" *ngIf="autoClubName !== 'W2C'">
              <div class="col-4">
                <p class="offc label mb-1">{{ autoClubName }} Member</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <span class="pe-3">{{
                    quoteEnquiry?.isClubMember === true ||
                    quoteEnquiry?.isClubMember === 'true'
                      ? 'Yes'
                      : 'No'
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="graybg ps-2">
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">
                  {{ selectedCover?.selectedCoverTitle }} Policy
                </p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{
                    step === 'step1'
                      ? (selectedCover?.selectedPrice?.basePremium
                        | number : '1.2-2')
                      : (quoteDetail?.premiums?.product | number : '1.2-2')
                  }}
                </p>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                quoteEnquiry?.isSingleTrip === false ||
                quoteEnquiry?.isSingleTrip === 'false'
              "
            >
              <div class="col-4">
                <p class="offc label mb-1">
                  {{ selectedCover?.selectedPrice?.multiTripLength }} Max Length
                  Trip
                </p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{
                    selectedCover.selectedPrice?.multiTripLengthPremium
                      | number : '1.2-2'
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">
                  {{ selectedCover?.selectedPrice?.excess }} Policy Excess
                </p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{
                    step === 'step1'
                      ? (selectedCover?.selectedPrice?.excessPremium
                        | number : '1.2-2')
                      : (quoteDetail?.premiums?.excess | number : '1.2-2')
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Cruise</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <span class="pe-3"
                    >${{
                      step === 'step1'
                        ? (selectedCover?.selectedPrice?.cruisePremium
                          | number : '1.2-2')
                        : (quoteDetail?.premiums?.cruise | number : '1.2-2')
                    }}</span
                  ><span class="pe-2"></span
                  ><span hidden class="text-gray">6 days</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Ski/Winter Sports</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <span class="pe-4"
                    >${{
                      step === 'step1'
                        ? (selectedCover?.selectedPrice?.skiingPremium
                          | number : '1.2-2')
                        : (quoteDetail?.premiums?.skiing | number : '1.2-2')
                    }}</span
                  ><span class="pe-2"></span>
                </p>
              </div>
            </div>
            <div class="row" *ngIf="autoClubName !== 'W2C'">
              <div class="col-4">
                <p class="offc label mb-1">Membership Discount</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  <span
                    class="pe-3"
                    *ngIf="
                      selectedCover?.selectedPrice?.membershipDisc +
                        quoteDetail?.premiums?.membershipDiscount <
                      0
                    "
                  >
                    - ${{
                      step === 'step1'
                        ? (-selectedCover?.selectedPrice?.membershipDisc
                          | number : '1.2-2')
                        : (-quoteDetail?.premiums?.membershipDiscount
                          | number : '1.2-2')
                    }}</span
                  >
                  <span
                    class="pe-3"
                    *ngIf="
                      selectedCover?.selectedPrice?.membershipDisc +
                        quoteDetail?.premiums?.membershipDiscount ===
                      0
                    "
                  >
                    $0.00</span
                  >
                </p>
              </div>
            </div>
            <div class="row" *ngIf="step !== 'step1'">
              <div class="col-4">
                <p class="offc label mb-1">Medical Conditions</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{ quoteDetail?.premiums?.medical | number : '1.2-2' }}
                </p>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                selectedCover?.selectedPrice?.promoCodeDisc < 0 ||
                quoteDetail?.premiums?.promoCodeDiscount < 0
              "
            >
              <div class="col-4">
                <p class="offc label mb-1">Promo Discount</p>
              </div>
              <div class="col-8">
                <span class="text-danger">
                  - ${{
                    step === 'step1'
                      ? (-selectedCover?.selectedPrice?.promoCodeDisc
                        | number : '1.2-2')
                      : (-quoteDetail?.premiums?.promoCodeDiscount
                        | number : '1.2-2')
                  }}
                </span>
                <span class="promo"
                  >Promo Code:
                  {{
                    step === 'step1'
                      ? quoteEnquiry?.promoCode
                      : quoteDetail?.promoCode
                  }}
                  <!-- <span class="close-promo bg-transparent">x</span> -->
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">GST</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{
                    step === 'step1'
                      ? (selectedCover?.selectedPrice?.gst | number : '1.2-2')
                      : (quoteDetail?.premiums?.gst | number : '1.2-2')
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <p class="offc label mb-1">Stamp Duty</p>
              </div>
              <div class="col-8">
                <p class="fw-semibold mb-1">
                  ${{
                    step === 'step1'
                      ? (selectedCover?.selectedPrice?.stampDuty
                        | number : '1.2-2')
                      : (quoteDetail?.premiums?.stampDuty | number : '1.2-2')
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-12 offcanvasbuttons">
          <div class="offcanvastop">
            <div class="hideonlarge row">
              <p class="offclabel col-4 smcost">Total Cost</p>
              <h3 class="col-8 smprice">
                ${{
                  step === 'step1'
                    ? (selectedCover?.selectedPrice?.totalPremium
                      | number : '1.2-2')
                    : (quoteDetail?.premiums?.totalCost | number : '1.2-2')
                }}
              </h3>
            </div>
            <a
              class="btn btn-outline-info btn-lg ps-2 pe-2 mt-1"
              (click)="openSaveAndSendModal()"
              >Save & Email Quote</a
            >
            <a
              (click)="downloadPDS()"
              class="btn btn-outline-info btn-lg ps-2 pe-2 mt-3"
              >Download PDS</a
            >
            <app-email-quote #saveAndSendEmail></app-email-quote>
          </div>
          <div class="offcanvasbottom">
            <div class="hideonsmall">
              <p class="offclabel">Total Cost</p>
              <h3>
                ${{
                  step === 'step1'
                    ? (selectedCover?.selectedPrice?.totalPremium
                      | number : '1.2-2')
                    : (quoteDetail?.premiums?.totalCost | number : '1.2-2')
                }}
              </h3>
            </div>
            <a
              *ngIf="step === 'step1'"
              class="btn btn-info btn-lg ps-5 pe-5 mt-3"
              (click)="onSubmitClick()"
              data-bs-dismiss="offcanvas"
              >Proceed</a
            >
            <button
              *ngIf="step === 'step2'"
              class="btn btn-info btn-lg mt-3"
              (click)="onSubmitClick()"
              [disabled]="!enableSubmitBtn"
              data-bs-dismiss="offcanvas"
            >
              Confirm & Finalise Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
