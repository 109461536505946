import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'china-iran',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './china-iran.component.html',
  styleUrl: './china-iran.component.scss',
})
export class ChinaIranComponent {
  /**
   *
   */
  constructor(private titleService: Title) {
    this.titleService.setTitle('Inactive travel alert - China and Iran | RAA');
  }
}
