<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Israel and the Occupied Palestinian Territories</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container page-content-info">
      <h3>IMPORTANT UPDATE – ISRAEL EFFECTIVE 1.30PM, 1ST NOVEMBER 2024</h3>
      <p>
        DFAT has changed its travel advisory from ‘Do Not Travel’ to 
        ‘Reconsider your need to travel’ for Israel and the Occupied 
        Palestinian Territories, effective 1.30pm on 1st November 2024.
      </p>
      <p>
        Please refer to <a href="https://www.smartraveller.gov.au/" target="_blank">
        www.smartraveller.gov.au</a> for further information.
      </p>
      <p>
        Please refer to our Travel Alert Update on 9th October 2024 below 
        and note that our embargo on all new travel insurance coverage to 
        Israel remains in force until further notice. All other information 
        in that earlier update remains relevant to the situation in Israel, 
        including for customers due to travel or who are already in Israel.
      </p>
      <p>
        As a result, no new travel insurance cover for travel to Israel 
        can be purchased and Israel cannot be added as a destination to 
        existing policies until further notice.
      </p>
      <p>
        Limits, conditions and exclusions apply under your travel insurance 
        policy. Please refer to the Product Disclosure Statement and your 
        Certificate of Insurance for full details.
      </p>
      
      <h3>IMPORTANT UPDATE – ISRAEL EFFECTIVE 7.30PM, 14TH OCTOBER 2024</h3>
      <p>
        DFAT has upgraded its travel advisory to ‘Do Not Travel’ for Israel 
        and the Occupied Palestinian Territories, due to the volatile 
        security situation, armed conflict, civil unrest and terrorism risk, 
        effective 7.30pm on 14th October 2024.
      </p>
      <p>
        Please refer to <a href="https://www.smartraveller.gov.au/" target="_blank">
        www.smartraveller.gov.au</a> for further information.
      </p>
      <p><strong>Travel policy coverage to Israel</strong></p>
      <p>
        Travel to a destination where there is an Australian Government 
        ‘Do Not Travel’ advisory in place is generally not covered by our 
        travel insurance policies. Refer to your policy documentation to 
        review the specific terms and exclusions they contain.
      </p>
      <p><strong>Customers due to travel to Israel</strong></p>
      <p>
        Travel to a destination where there is an Australian Government 
        ‘Do Not Travel’ advisory in place is generally not covered by our 
        travel insurance policies. Refer to your policy documentation to 
        review the specific terms and exclusions they contain.
      </p>
      <p>
        If your trip is affected and you wish to make a claim for our 
        consideration, you can do so.
      </p>
      <p><strong>Customers already in Israel</strong></p>
      <p>
        We advise you to contact our 24-hour Emergency Assistance Team for 
        any queries or assistance in departing Israel as soon as possible, 
        given the change in the travel advisory to Do Not Travel. Refer to 
        your policy document for contact details.
      </p>
      <p>
        If you are an Australian citizen or permanent resident in Israel 
        or the Occupied Palestinian Territories, the Department of Foreign 
        Affairs and Trade (DFAT) encourages travellers to register with its 
        crisis registration portal. See the 'Safety' section if their website, 
        for details on how to register on <a href="https://www.smartraveller.gov.au/" 
        target="_blank">smartraveller.gov.au</a>
      </p>
      <p>
        Australians needing emergency consular assistance should contact the 
        Australian Government's 24-hour Consular Emergency Centre on 
        <a href="tel:1300 555 135">1300 555 135</a> (within Australia) or 
        <a href="tel:+61 2 6261 3305">+61 2 6261 3305</a> (from overseas).
      </p>
      <p>
        Limits, conditions and exclusions apply under your travel insurance 
        policy. Please refer to the Product Disclosure Statement and your 
        Certificate of Insurance for full details.
      </p>
      
      <h3>
        IMPORTANT UPDATE – ISRAEL EFFECTIVE 9TH OCTOBER 2024
      </h3>
      <p>
        Effective from 09 October 2024, we have applied an embargo on the 
        sales of all new travel insurance coverage to Israel, due to the 
        deteriorating security and escalation of the conflict in the region.
      </p>
      <p>
        Whilst the Australian Government’s travel advice is to reconsider 
        your need to travel to Israel, we have assessed this against our 
        insurance risk appetite and whether our travel insurance would 
        provide adequate coverage under the circumstances, noting the 
        specific terms and exclusions relevant to the current conflict. 
        We have made the decision to cease offering cover.
      </p>
      <p><strong>As a result, no new travel insurance cover for travel to 
        Israel can be purchased and Israel cannot be added as a destination 
        to existing policies until further notice from 09 October 2024.
      </strong></p>
      <p><strong>Travel policy coverage to Israel</strong></p>
      <p>
        Acts of war as well as a traveller’s choice to travel to a 
        destination where there is an Australian Government ‘Do Not Travel’ 
        advisory in place are generally not covered by our travel insurance 
        policies. Refer to your policy documentation to review the specific 
        terms and exclusions they contain.
      </p>
      <p><strong>Customers due to travel to Israel</strong></p>
      <p>
        If you have a travel insurance policy and are traveling to Israel in 
        the near future, you will continue to be covered to the extent of the 
        terms of your policy, unless the Australian Government changes its 
        Smart Traveller advice for Israel to ‘Do Not Travel’, in which case 
        further policy coverage limitations and exclusions would apply.
      </p>
      <p>
        If your trip is affected and you wish to make a claim for our 
        consideration, you can do so.
      </p>
      <p><strong>Customers already in Israel</strong></p>
      <p>
        If you are already in Israel you will continue to be covered to the 
        extent of the terms of your policy but in the event of a change in 
        travel advice to ‘Do Not Travel’ we advise you to contact our 24 hour 
        Emergency Assistance Team for any queries and assistance in departing 
        Israel as soon as possible – refer to your policy document for contact 
        details.
      </p>
      <p>
        Limits, conditions and exclusions apply under your policy. Please 
        refer to the Product Disclosure Statement and your Certificate of 
        Insurance for full details.
      </p>

      <h3>
        IMPORTANT UPDATE – ISRAEL AND THE OCCUPIED PALESTINIAN TERRITORIES
      </h3>
      <p>
        On 7 October 2023, Israel declared a state of war in response to a
        reported attack by Hamas. Thousands have been killed or injured, and
        hostages have been taken. At the date of publication of this advice, the
        military conflict remains ongoing.
      </p>
      <p><strong>DFAT advice as at 09/10/2023:</strong></p>
      <ul>
        <li>
          On 08/10/2023, DFAT has listed travel advice as ‘Do not travel to
          Gaza’ due to the dangerous and uncertain security situation.
        </li>
        <li>
          As at 08/10/2023, DFAT advise to exercise a high degree of caution in
          Israel and the Occupied Palestinian Territories overall due to civil
          unrest and the threat of terrorist attack, rocket fire and military
          confrontation.
        </li>
      </ul>
      <p><strong>DFAT updated advice as at 21/10/2023:</strong></p>
      <ul>
        <li>
          DFAT advise do not travel to the West Bank (excluding East Jerusalem)
          due to the volatile security situation.
        </li>
      </ul>
      <p>
        The situation is highly challenging and rapidly changing. We understand
        that you may have questions and concerns about your travel insurance
        coverage whether you are currently in Israel or planning to travel.
      </p>
      <h2>Travel Insurance Coverage</h2>
      <p>
        Acts of war and your choice to travel to a destination where there are
        Do Not Travel warnings in place are generally not covered by many of our
        travel insurance policies. We recommend that you refer to your policy
        documentation to review the specific terms and conditions that apply.
      </p>
      <p>
        <strong>Customers Currently in Israel</strong>: If you are currently in
        Israel and require emergency assistance, please contact our emergency
        assistance team on +61 2 8055 1698.
      </p>
      <p>
        <strong>Customers that are Due to Travel</strong>: The DFAT advice for
        the region is to reconsider your need to travel to Israel and the
        Occupied Palestinian Territories overall due to the volatile security
        situation, including the threat of terrorism, armed conflict and civil
        unrest. Furthermore, there is a ‘Do Not Travel’ warning for Gaza and
        border areas, and the West Bank (excluding East Jerusalem) due to
        ongoing armed conflict.
      </p>
      <p>
        If you have booked travel but have not yet departed, we recommend you
        contact your travel agent or travel provider in the first instance about
        the best way to alter your trip as some travel providers may provide
        penalty-free options to amend your travel arrangements or refunds or
        credits may be available to cancel your trip.
      </p>
      <p>
        If your trip is affected and you wish to submit a claim for
        consideration you can do so via:
      </p>
      <p>
        Web:
        <a class="link" target="_blank" routerLink="/how-to-claim"
          >how-to-claim</a
        >
      </p>
      <p>Phone: 1300 207 365</p>
      <p>
        Email:
        <a href="mailto:raaclaims@tmnfatravelinsurance.com.au"
          >raaclaims&#64;tmnfatravelinsurance.com.au</a
        >
      </p>
      <p>
        Limits, conditions and exclusions apply under your policy. Please refer
        to the Product Disclosure Statement and your Certificate of Insurance
        (received when you purchased your travel insurance) for full details.
      </p>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
