<section class="mt-2  mt-md-5">
  <div class="container pt-4">
    <div class="row">
      <div class="col-10 offset-1">
        <div class="progress px-1" style="height: 3px">
          <div
            class="progress-bar bg-info"
            role="progressbar"
            [style]="customStyles"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="step-container d-flex justify-content-between">
          @if(activeStep === 'step1') {
          <div class="bar_01">
            <div class="step-circle bg-info text-white">1</div>
            <span class="step-text fw-semibold" style="margin-left: -28px"
              >Select Cover</span
            >
          </div>
          <div class="bar_02">
            <div class="step-circle bg-gray border-light text-white">2</div>
            <span class="step-text fw-semibold">Details</span>
          </div>
          <div class="bar_03">
            <div class="step-circle bg-gray border-light text-white">3</div>
            <span class="step-text fw-semibold">Confirm</span>
          </div>
          <div class="bar_04">
            <div class="step-circle bg-gray border-light text-white">4</div>
            <span class="fw-semibold" style="margin-right: -15px">Payment</span>
          </div>
          } @else if(activeStep === 'step2') {
          <div class="bar_01">
            <div class="step-circle bg-info border-info">
              <a href="quotes-steps-one" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span
              class="step-text fw-semibold text-info"
              style="margin-left: -28px"
              >Select Cover</span
            >
          </div>
          <div class="bar_02">
            <div class="step-circle bg-info text-white">2</div>
            <span class="step-text fw-semibold">Details</span>
          </div>
          <div class="bar_03">
            <div class="step-circle bg-gray border-light text-white">3</div>
            <span class="step-text fw-semibold">Confirm</span>
          </div>
          <div class="bar_04">
            <div class="step-circle bg-gray border-light text-white">4</div>
            <span class="fw-semibold" style="margin-right: -15px">Payment</span>
          </div>
          } @else if (activeStep === 'step3') {
          <div class="bar_01">
            <div class="step-circle bg-info border-info">
              <a href="quotes-steps-one" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span
              class="step-text fw-semibold text-info"
              style="margin-left: -28px"
              >Select Cover</span
            >
          </div>
          <div class="bar_02">
            <div
              class="step-circle bg-info border-info"
              onclick="displayStep(2)"
            >
              <a href="quotes-steps-two" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span class="step-text fw-semibold text-info">Details</span>
          </div>
          <div class="bar_03">
            <div class="step-circle bg-info text-white">3</div>
            <span class="step-text fw-semibold">Confirm</span>
          </div>
          <div class="bar_04">
            <div class="step-circle bg-gray border-light text-white">4</div>
            <span class="fw-semibold" style="margin-right: -15px">Payment</span>
          </div>
          } @else if (activeStep === 'step4') {
          <div class="bar_01">
            <div class="step-circle bg-info border-info">
              <a href="quotes-steps-one" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span
              class="step-text fw-semibold text-info"
              style="margin-left: -28px"
              >Select Cover</span
            >
          </div>
          <div class="bar_02">
            <div class="step-circle bg-info border-info">
              <a href="quotes-steps-two" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span class="step-text fw-semibold text-info">Details</span>
          </div>
          <div class="bar_03">
            <div class="step-circle bg-info border-info">
              <a href="quotes-steps-three" class="text-white"
                ><i class="fa-solid fa-check"></i
              ></a>
            </div>
            <span class="step-text fw-semibold text-info">Confirm</span>
          </div>
          <div class="bar_04">
            <div class="step-circle bg-info text-white">4</div>
            <span class="fw-semibold" style="margin-right: -15px">Payment</span>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
