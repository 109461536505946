<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Virgin Airline</h1>
    </div>
  </section>
  <div class="container page-content py-5">
    <p>
      <strong
        ><span>Travel insurance information for Virgin Australia</span></strong
      >
    </p>
    <p>
      <span
        >Virgin Australia announced the morning of 21 April 2020 it has entered
        voluntary administration.
      </span>
    </p>
    <p>
      <span
        >In a statement to the ASX, the airline said the move would help
        "recapitalise the business" and ensure it emerged "in a stronger
        financial position on the other side of the COVID-19 crisis".</span
      >
    </p>
    <p>
      <span
        ><a
          class="link"
          href="https://www.abc.net.au/news/2020-04-21/virgin-australia-goes-into-voluntary-administration-coronavirus/12167814"
          target="_blank"
          >https://www.abc.net.au/news/2020-04-21/virgin-australia-goes-into-voluntary-administration-coronavirus/12167814</a
        ></span
      >
    </p>
    <p>
      <span
        >All claims relating to this event will be assessed based on your
        circumstances and in accordance with the Product Disclosure Statement
        (PDS) applicable to your policy level.
      </span>
    </p>
    <p>
      <strong
        ><span
          >If your policy was purchased before 9am (AEST) on 21 April 2020
          –</span
        ></strong
      >
    </p>
    <p>
      <span
        >There may be coverage under Section 10 Financial Default, but this can
        only be determined once a formalised statement from the administrators
        has been issued. At this stage we are unaware how current bookings,
        refunds, or credits will be affected. As the situation continues to
        develop, we advise to await further information from the administrators
        on their formal position of the future of the airline.
      </span>
    </p>
    <p>
      <strong
        ><span
          >If you purchased your policy after 9am (AEST) on 21 April 2020
          –</span
        ></strong
      >
    </p>
    <p>
      <span
        >Our policy does not provide cover for any event you were aware of
        before your policy cover commenced.&nbsp; If you purchased your policy
        after 21 April 2020, we would expect this was done with knowledge of the
        current Virgin Australia event and therefore there would be no coverage
        for any claim which was a result of this event.</span
      >
    </p>
    <p>
      <span
        >We will continue to monitor the situation and advise accordingly.
      </span>
    </p>
  </div>

  <app-racv-footer></app-racv-footer>
</div>
