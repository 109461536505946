<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/refunds.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Refunds notice</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <h1>Cancelling within the cooling-off period</h1>
      <p>
        You have 21 days from the day You purchase Your policy to decide if the
        cover is right for You and suits Your needs. If the policy does not meet
        Your needs You can cancel Your policy within this “cooling-off period”
        for a full refund, provided You:
      </p>
      <ul>
        <li>Haven’t started Your Trip; and/or</li>
        <li>Haven’t made a claim; and/or</li>
        <li>
          Don’t intend to make a claim or exercise any other rights under Your
          policy.
        </li>
      </ul>
      <p>
        Simply contact Us on
        <a href="tel:+61882024346" class="text-blue">+61 8 8202 4346</a> within
        the cooling-off period and We can arrange this for You.
      </p>
      <h1>Cancellation outside the cooling-off period</h1>
      <p>
        If You would like to cancel Your policy outside the cooling-off period
        then You can request Us to consider this, provided You:
      </p>
      <ul>
        <li>Haven’t started Your Trip; and/or</li>
        <li>Haven’t made a claim; and/or</li>
        <li>
          Don’t intend to make a claim or exercise any other rights under Your
          policy.
        </li>
      </ul>
      <p>
        We will consider Your request and may at Our discretion provide You with
        a pro-rated refund. This refund (and Our decision in providing You with
        a refund which will not be unreasonably withheld) will be based on
        numerous factors including:
      </p>
      <ul>
        <li>The level of cover/policy type chosen; and/or</li>
        <li>
          The date You purchased Your Policy and the date the Trip would have
          begun; and/or
        </li>
        <li>Any other extenuating circumstances.</li>
      </ul>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
