import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'old-covid-faqs',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './old-covid-faqs.component.html',
  styleUrl: './old-covid-faqs.component.scss',
})
export class OldCovidFaqsComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Inactive travel alert - COVID-19 FAQs | RAA');
  }
}
