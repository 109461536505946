<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'HowToClaim'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/how-to-claim.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>How to Claim</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <h3>Claims</h3>
      <h3>Online Claim</h3>
      <p>
        The quickest way to lodge a claim on your RAA Travel Insurance policy is
        using our online claim lodgement which is available 24/7. Follow these
        easy steps to lodge your claim online:
      </p>
      <ol>
        <li>
          You will first need to register by utilising the email address you
          used to purchase your policy
        </li>
        <li>
          Once registered you will receive an email to guide you to setup your
          password
        </li>
        <li>
          If you have previously registered a claim just login using your email
          and password
        </li>
        <li>
          Once you have logged in, complete your claim details by answering all
          sections
        </li>
        <li>
          Once submitted, your claim will be assessed within 10 working days and
          we will let you know if the insurer requires any further
          information/documentation from you.
        </li>
      </ol>
      <br />
      <p>
        <a
          href="https://raa-onlineclaim.tmnfatravelinsurance.com.au/Account/Register"
          target="_new"
          class="btn btn-link"
          >Register</a
        >
        or
        <a
          href="https://raa-onlineclaim.tmnfatravelinsurance.com.au/Account/Login"
          target="_new"
          class="btn btn-link"
          >Login</a
        >
      </p>
      <h3>Call us</h3>
      <p>
        Please contact our claims team on
        <a href="tel:+1300207365 " class="link">1300 207 365</a> and we'll
        happily take your details to lodge a claim and guide you through the
        claim process.
      </p>
      <h3>24-hour overseas emergency assistance</h3>
      <p>
        If something unexpected happens while you are overseas, we want to
        ensure we can help make it as stress free as possible. The team of
        emergency assistance experts will help you. <br /><br />
        All international RAA travel insurance policyholders have access to the
        24 hour assistance team 24 hours a day, 365 days a year. They are just a
        phone call away.
        <br /><br />
        Please be aware too that if you are hospitalised or receiving outpatient
        treatment exceeding $A2000, you must make contact using the emergency
        assistance numbers below as soon as possible.
        <br /><br />
        Reverse charges accepted from the overseas operator on
        <a href="tel:+61280551698 " class="link">+61 2 8055 1698</a>
      </p>
      <p></p>
      <h2>Interpreting Services</h2>
      <p>
        We are pleased to offer assistance in the following ways if you need
        help understanding any aspect of your travel insurance policy or claim
        in your language.
      </p>
      <h2>Contact Translating and Interpreting Services</h2>
      <p>
        Translating and Interpreting Services (TIS) are available 24/7 and their
        interpreters speak over 160 languages. They're available over the phone
        and in person. Book an
        <a
          rel="noopener noreferrer"
          href="https://www.tisnational.gov.au/"
          target="_blank"
          class="link"
          >appointment with TIS</a
        >, or call them on <a href="tel:+131450 " class="link">131 450</a>. This
        is a free service.
      </p>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
