import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { PDSService } from '../../services/pds.service';
import { LocalCacheService } from '../../services/localCache.service';

@Component({
  selector: 'app-racv-footer',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './racv-footer.component.html',
  styleUrl: './racv-footer.component.scss',
})
export class RacvFooterComponent {
  dynamicClassName: string = '';
  pdsLink: string = '';
  tmdLink: string = '';
  constructor(private router: Router, private pdsService: PDSService, private localCacheService: LocalCacheService) { }
  
  ngOnInit() { 
    let path = this.router.url.split('/')?.filter(Boolean)?.join('-');
    if (!path) {
      path = 'homepage';
    }
    this.dynamicClassName = path;
    const currentLatestPDS = this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
    const currentLatestTMD = this.localCacheService.getSessionStorage('lastestTMD');
    if(!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if(obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
  }
}
