<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/Terms-and-Conditions.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Terms and Conditions</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <!-- <h1>Terms and conditions</h1> -->
      <p>1. Access to and use of this site and the products and services available through this site are subject to the following terms of use. By using this site you agree to be bound by these Terms and Conditions.</p>
      <p>2. ‘Our’, ‘Us’ and ‘We’ refers to each of Royal Automobile Association of South Australia and Tokio Marine and Nichido Fire Insurance Co. Ltd. (Tokio Marine &amp; Nichido) ABN 80 000 438 291, AFSL 246548 as required in this document. The words ‘you’ and ‘your’ refer to users of this public site.</p>
      <p>3. The information in this site is a summary only. Source material should be consulted in all cases affecting legal rights or responsibilities. Changes in circumstances may occur at any time and may impact on the accuracy or completeness of the information. However, to the extent permitted by law, we do not warrant the accuracy, adequacy or completeness of the information and excludes liability for any decision taken on the basis of information shown in or omitted from this site.</p>
      <p>4. The material on this site is published by us and is intended for use in Australia. The site is not intended for use outside Australia. We make no claim that the information contained on this site is appropriate or may be downloaded legally outside Australia. If you access this site from outside Australia, you do so at your own risk and you are responsible for compliance with the laws in your respective jurisdiction.</p>
      <p>5. This site is not intended for persons under the age of 18. If you are under 18, you should speak to your parents, your guardian, or a responsible adult and obtain their permission to use this site.</p>
      <p>6. The information, materials and services contained on this site are provided to you ‘as is’, and ‘as available’. To the maximum extent permitted by law, unless otherwise specified, we disclaim all representations and warranties of any kind, whether express, implied or statutory. In addition, we do not warrant, guarantee or make any representations regarding the security of accounts, or that this site is free from destructive materials, including but not limited to computer viruses, hackers, or other technical sabotage.</p>
      <p>7. We also do not warrant, guarantee or make any representations that access to this site will be fully accessible at all times, uninterrupted, or error-free. Use of this site is limited to obtaining insurance quotes, obtaining, other information relating to the price of insurance cover and conducting insurance related transactions or queries, in respect of insurance cover for you or a member of your immediate family, with their prior consent. You agree not to otherwise use this site to obtain insurance quotes, or to obtain other information relating to the price of insurance cover from us or conduct insurance-related transactions or queries. You also agree to indemnify us in respect of any liability incurred by us for any loss, cost damage or expense, howsoever caused, suffered by us as a result of your breach of this obligation.</p>
      <p>8. In using this site you agree that you will not, either directly, or indirectly through another party, use any robot, spider, screen scraper, data aggregation tool or other device or process to process, monitor, copy or extract any web pages on this site, or any of the information, content or data contained within or accessible through this site, without our prior written permission. Further you agree not to use any process to aggregate or combine information, content or data contained within or accessible through this site with information, content or data accessible via or sourced from any third party. In addition you agree not to use any information on or accessed through the site for any commercial purpose or otherwise (either directly or indirectly) for profit or gain. You also agree not to reverse engineer, reverse assemble, decompile, or otherwise attempt to discover source code or other arithmetical formula or processes in respect of the software underlying the infrastructure and processes associated with this site, or copy, reproduce, alter, modify, create derivative works, or publicly display, any part of any content from this site without our prior written permission.</p>
      <p>9. To the maximum extent permitted by law, we disclaim liability for any damages, including, without limitation, direct or indirect, special, incidental, compensatory, exemplary or consequential damages, losses or expenses, including without limitation lost or misdirected orders, lost profits, lost goodwill, or lost or stolen programs or other data, however caused and under any theory of liability arising out of or in connection with (1) use of this site, or the inability to use this site by any party; or 2) any failure or performance, error, omission, interruption, defect, delay in operation or transmission; or (3) line or system failure or the introduction of a computer virus, or other technical sabotage, even if we or our employees or representatives are advised of the possibility or likelihood of such damages, losses or expenses.</p>
      <p>10. All names and marks depicted at this site are owned by one or more of us and no permission is given to you to make any use (including any reproduction) of them.</p>
      <p>11. This site may contain a number of links to other sites which are operated by third parties. The provision of the link does not mean that we endorse or accept any responsibility for the content, use, or products and services made available through the associated web sites. By utilising these links, you acknowledge and agree that we are not responsible for the actions, content, accuracy, privacy policies, opinions expressed, services provided, goods sold, or other links provided by these sites. You further acknowledge and agree that we are not responsible either directly or indirectly for any damage or loss caused by use of or reliance on linked sites. Should you have any questions regarding these sites, you should address them directly to our contact shown on this site.</p>
      <p>12. This agreement is governed by the law in force in the state of New South Wales, Australia. Any dispute arising in connection with these terms or in connection with your use of this site which cannot be resolved by good faith negotiations between you and us shall be referred to mediation or to arbitration. However, we reserve, at our absolute discretion, our right to instigate legal proceeding prior to negotiation, arbitration or mediation against any individual, organisation or entity, who in our opinion has acted in breach of these Terms and Conditions.</p>
        </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
