<section id="header" class="header w-100 sticky-top solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-8 mobile-width-pas-text">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-4 mobile-width-pas-button">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid borderonscroll d-none d-lg-block"
    [ngClass]="{ 'has-pas-header': isPASUser }"
  >
    <div class="header-top pt-1 pb-1">
      <div class="container-xl">
        <div class="row justify-content-center justify-content-lg-end">
          <div class="col text-end">
            <img class="call-icon" src="assets/icons/raa/headset-tick.svg" />
            <span class="number text-primary me-2"
              ><a href="tel:+61882024346" class="text-primary">
                +61 8 8202 4346</a
              ></span
            >
            <span class="dayntime">Mon-Fri 8am-6pm, Sat 9am-12pm </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="container-fluid shadowonscroll"
    [ngClass]="{ 'has-pas-header-mobile': isPASUser }"
  >
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl navwithbg p-0">
            <a class="navbar-brand" href="index.html"
              ><img class="mainlogo" src="assets/images/raa/svg-logo.png"
            /></a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/raa/menu.png"
              /></span>
            </button>

            <div
              class="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a class="navbar-brand" href=""
                    ><img class="mainlogo" src="assets/images/raa/svg-logo.png"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      href="https://travel.raa.com.au/"
                      target="_blank"
                      >Home</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" href="travel-alerts" target="_blank"
                      >Travel Alerts</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      href="https://travel.raa.com.au/contact/faq/travel-insurance/"
                      target="_blank"
                      >FAQs</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="{{ pdsLink }}"
                      >Product Disclosure Statement</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" href="how-to-claim" target="_blank"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="cruise">Cruise</a>
                  </li>
                </ul>
              </div>
              <!-- Visible only in mobile menu -->
              <div
                class="offcanvas-footer offcanvas-header border-top flex-column text-center"
              >
                <div class="col-12">
                  <a href="tel:+61882024346"
                    ><i class="fa-solid fa-phone"></i>
                    <span class="fw-semibold"> +61 8 8202 4346</span></a
                  >
                </div>
                <div class="col-12">
                  <i class="fa-solid fa-business-time"></i>
                  <span class=""> Mon-Fri 8am-6pm, Sat 9am-12pm</span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>
