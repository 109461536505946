<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header [activeHeader]="'FAQs'"></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Frequently Asked Questions (FAQs)</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <h1 style="margin-top: 100px">Frequently Asked Questions (FAQs)</h1>
      <p class="lead">
        These FAQ's should be read in conjunction with the RACT Travel Insurance
        <span style="text-decoration: underline"
          ><a
            href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
            >Combined Financial Services Guide and Product Disclosure Statement
            (PDS)</a
          ></span
        >
        available from RACT and attached
        <a
          href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
          ><strong
            ><span style="text-decoration: underline">here</span></strong
          ></a
        >. You should consider the PDS before making a decision about whether to
        acquire or to continue to hold this insurance.
      </p>
      <p>
        RACT Travel Insurance is issued by Tokio Marine &amp; Nichido Fire
        Insurance Co. Ltd. (Tokio Marine &amp; Nichido) ABN 80 000 438 291, AFSL
        246 548. The Royal Automobile Club of Tasmania Limited (RACT) (ABN 62
        009 475 861 AR 000228578) (RACT 'our', 'us' and 'we') is an Authorised
        Representative of Tokio Marine &amp; Nichido. Where we provide financial
        services in relation to RACT Travel Insurance (such as financial advice
        or arranging for the issue of insurance), RACT is acting as an
        Authorised Representative of the issuer of the insurance, Tokio Marine
        &amp; Nichido.
      </p>

      <section class="accordion accordion--radio">
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd1" style="" />
          <label for="rd1" class="tab__label"
            >Why should I consider getting travel insurance?</label
          >
          <div class="tab__content">
            <p>
              Travel Insurance can provide some protection when unforeseen
              events occur whilst you are travelling and can help cover
              unexpected expenses. Travel Insurance can provide some cover for
              things like theft, accidents, or medical problems. To find out
              more about the benefits of travel insurance, visit the
              <span style="text-decoration: underline"
                ><a href="https://www.dfat.gov.au/"
                  ><strong
                    >Department of Foreign Affairs and Trade website</strong
                  ></a
                >.</span
              >
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd2" style="" />
          <label for="rd2" class="tab__label"
            >What does travel insurance cover?</label
          >
          <div class="tab__content">
            <p>
              All of our RACT International policies provide 24-hour medical
              emergency assistance and unlimited reasonable overseas medical
              treatment (conditions, sub-limits and exclusions apply). We offer
              a number of policy options with varying benefits and limits for
              both International travel and Domestic travel, as well as a
              domestic rental car excess only policy. The policy also covers
              your Dependents such as children, grandchildren , step-children
              and foster children at no extra cost, provided they are travelling
              with you, are up to the age of 25, are financially dependent on
              their parents, are not working full time, and do not require
              medical assessment. Please refer to the table of benefits in the
              <a
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><span style="text-decoration: underline"
                  ><strong>Product Disclosure Statement</strong></span
                ></a
              >
              for further details.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd3" style="" />
          <label for="rd3" class="tab__label">Who is the insurer?</label>
          <div class="tab__content">
            <p>
              RACT Travel Insurance is issued by Tokio Marine &amp; Nichido Fire
              Insurance Co., Ltd (Tokio Marine &amp; Nichido) ABN 80 000 438
              291, AFSL 246548. Its managing agent, Tokio Marine Management
              (Australasia) Pty. Ltd. ABN 69 004 488 455 (TMMA) is authorised
              under a binder to act on behalf of Tokio Marine &amp; Nichido to
              issue its policies and handle and settle claims in relation to
              those policies, subject to the terms of the authority. The Royal
              Automobile Club of Tasmania Limited (ABN 62 009 475 861 AR
              000228578) (RACT) is an authorised representative of Tokio Marine
              &amp; Nichido.
            </p>
            <p>
              As well as travel insurance, Tokio Marine &amp; Nichido also work
              with the general insurance market through insurance brokers and
              provide insurance for commercial and corporate businesses in
              Australia. Tokio Marine &amp; Nichido was founded in 1879 in
              Japan, operates in multiple countries and employs thousands of
              people worldwide.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd4" style="" />
          <label for="rd4" class="tab__label">Who can get insured?</label>
          <div class="tab__content">
            <p>
              Cover is available to Australian citizens and Australian permanent
              residents up to 110 years of age for Single Trip policies and up
              to 75 years of age for the Annual Multi-Trip policy, provided:
            </p>
            <ul>
              <li>You purchase Your Policy before you begin Your Trip; and</li>
              <li>
                for International cover Your Trip begins and ends in Australia;
                or
              </li>
              <li>
                for Domestic cover Your trip must be wholly within Australia.
              </li>
            </ul>
            <p>
              Australian temporary residents up to 110 years of age for Single
              Trip policies and up to 75 years of age for Annual Multi-Trip
              policies, provided:
            </p>
            <ul>
              <li>
                you hold a current Australian Visa that will remain valid beyond
                the period of your return from Your Trip; and
              </li>
              <li>you hold a return ticket; and</li>
              <li>
                you have a primary place of residence in Australia that you
                intend to return to; and
              </li>
              <li>you purchase your policy before you begin your Trip; and</li>
              <li>
                for International cover your Trip begins and ends in Australia;
                or
              </li>
              <li>
                for Domestic cover your Trip must be wholly within Australia.
              </li>
            </ul>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd5" style="" />
          <label for="rd5" class="tab__label"
            >What is a medical assessment?</label
          >
          <div class="tab__content">
            <p>
              This is an online set of medical questions (assessment) which you
              will need to complete if you wish to apply for travel insurance
              but have Medical Conditions which are not automatically covered
              under the policy. You can complete this as part of your travel
              insurance quote at
              <a href="https://www.ract.com.au/travel-insurance"
                ><span style="text-decoration: underline"
                  ><strong>www.ract.com.au/travel-insurance</strong></span
                ></a
              >. You will be informed if the Medical Condition is covered and
              any additional premium payable.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd6" style="" />
          <label for="rd6" class="tab__label"
            >What is a Medical Condition?</label
          >
          <div class="tab__content">
            <p>
              A <i><strong>Medical Condition</strong></i> means any medical or
              physical condition, disorder, disease, disability or illness,
              including any <i><strong>Mental Illness</strong></i
              >, which at the <i><strong>Relevant Time, You</strong></i> were
              aware of, or a reasonable person in the circumstances could be
              expected to have been aware of, and at the
              <i><strong>Relevant Time</strong></i
              >:
            </p>
            <ol>
              <li>
                is chronic, ongoing, terminal, or has affected or involved one
                or more of the following:
                <ol style="list-style-type: lower-alpha">
                  <li>
                    heart, circulatory system, lungs or respiratory system,
                    brain, kidneys, liver, or cancer;
                  </li>
                  <li>
                    surgery involving the back, neck, joints, or abdomen; or
                  </li>
                </ol>
              </li>
              <li>
                in the last 24 months had:
                <ol style="list-style-type: lower-alpha">
                  <li>
                    presented symptoms which would have caused an ordinarily
                    prudent person to seek medical opinion or treatment;
                  </li>
                  <li>become exacerbated or complicated; or</li>
                  <li>
                    been diagnosed, treated or treatment was recommended by a
                    <i><strong>Treating Doctor</strong></i
                    >.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <strong>
                Capitalised terms used above are defined in the PDS.</strong
              >
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd7" style="" />
          <label for="rd7" class="tab__label"
            >Which Medical Conditions are automatically covered?</label
          >
          <div class="tab__content">
            <p>
              This section outlines those
              <i><strong>Medical Conditions</strong></i> automatically included,
              where <i><strong>You</strong></i> at the
              <i><strong>Relevant Time</strong></i
              >:
            </p>
            <ul>
              <li>
                have not required hospitalisation or treatment (where treatment 
                does not include an annual or routine medical check-up, blood 
                testing or a visit to a medical practitioner to obtain a regular 
                prescription) by any
                <i><strong>Treating Doctor</strong></i> within the last 24 months 
                (unless a different time-period is specifically listed in the 
                list below) for the <i><strong>Medical Condition</strong></i>;
              </li>
              <li>
                are not awaiting the outcome of any investigation, tests,
                surgery or other treatment for the
                <i><strong>Medical Condition</strong></i
                >; and
              </li>
              <li>
                meet any additional criteria set out in the
                <i><strong>Medical Conditions</strong></i> we automatically
                cover list below.
              </li>
            </ul>
            <p>
              If the criteria above are satisfied, cover is automatically
              included for the following
              <i><strong>Medical Conditions</strong></i
              >:
            </p>
            <p>
              <strong
                >Please also read the “General Exclusions” section of the PDS
                which can be viewed
                <span style="text-decoration: underline"
                  ><a
                    class="link"
                    href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                    >here</a
                  ></span
                >.</strong
              >
            </p>
            <ul style="list-style: decimal">
              <li>Acne</li>
              <li>
                Allergies limited to Rhinitis, Chronic Sinusitis, Eczema, Food
                Intolerance,Hay Fever, however this excludes any Anaphylaxis as
                part of any such condition
              </li>
              <li>
                Asthma providing You:
                <ol style="list-style-type: lower-alpha">
                  <li>have no other lung disease; and</li>
                  <li>
                    are less than 60 years of age at the time You purchase the
                    policy
                  </li>
                </ol>
              </li>
              <li>Bell's Palsy</li>
              <li>Benign Positional Vertigo</li>
              <li>Bunions</li>
              <li>Carpal Tunnel Syndrome</li>
              <li>Cataracts</li>
              <li>Coeliac Disease</li>
              <li>Congenital Blindness</li>
              <li>Congenital Deafness</li>
              <li>
                Diabetes&nbsp;<span>Mellitus (Type I) providing You: </span>
                <ol style="list-style-type: lower-alpha">
                  <li>were diagnosed over 24 months ago; and</li>
                  <li>
                    have no eye, kidney, nerve or vascular complications; and
                  </li>
                  <li>
                    do not suffer from a known cardiovascular disease,
                    hypertension, or hypercholesterolemia; and
                  </li>
                  <li>
                    are under 50 years of age at the date of policy purchase
                  </li>
                </ol>
              </li>
              <li>
                Diabetes Mellitus (Type II) providing You:
                <ol style="list-style-type: lower-alpha">
                  <li>were diagnosed over 24 months ago; and</li>
                  <li>
                    <span
                      >have no eye, kidney, nerve or vascular complications;
                      and</span
                    >
                  </li>
                  <li>
                    do not suffer from a known cardiovascular disease,
                    hypertension, or hypercholesterolemia; and
                  </li>
                  <li>
                    are under 50 years of age at the date of policy purchase
                  </li>
                </ol>
              </li>
              <li>Dry Eye Syndrome</li>
              <li>
                Epilepsy providing:
                <ol style="list-style-type: lower-alpha">
                  <li>
                    there has been no change to Your medication regime in the
                    past 24 months; and
                  </li>
                  <li>You are on no more than one anticonvulsant medication</li>
                </ol>
              </li>
              <li>Gastric reflux</li>
              <li>Gastric/Peptic ulcer</li>
              <li>Glaucoma</li>
              <li>Gout</li>
              <li>Graves' Disease</li>
              <li>Hiatus Hernia</li>
              <li>
                <span
                  >Hip/Knee replacement if performed more than 24 months ago but
                  less than 10 years ago</span
                >
              </li>
              <li>
                <span
                  >Hypercholesterolemia (High Cholesterol) providing You do not
                  also suffer from a known cardiovascular disease and/or
                  Diabetes</span
                >
              </li>
              <li>
                <span
                  >Hyperlipidemia (High Blood Lipids) providing You do not also
                  suffer from a known cardiovascular disease and/or
                  Diabetes</span
                >
              </li>
              <li>
                <span
                  >Hypertension (High Blood Pressure) providing You do not also
                  suffer from a known cardiovascular disease and/or
                  Diabetes</span
                >
              </li>
              <li>
                <span>Hypothyroidism, including Hashimoto’s Disease</span>
              </li>
              <li>Incontinence</li>
              <li>Insulin Resistance</li>
              <li>Macular Degeneration</li>
              <li>Meniere's Disease</li>
              <li>Migraine</li>
              <li><span>Nocturnal cramps</span></li>
              <li>Plantar Fasciitis</li>
              <li>Raynaud's Disease</li>
              <li><span>Sleep Apnoea</span></li>
              <li>Solar Keratosis</li>
              <li><span>Trigeminal Neuralgia</span></li>
              <li>Trigger Finger</li>
              <p>
                If <i><strong>You</strong></i> require cover for
                <i><strong>Medical Conditions</strong></i> that are not
                automatically covered above, <i><strong>You</strong></i> may
                apply for additional cover for that condition.
              </p>
              <p>
                If your condition does not meet the automatically covered
                conditions criteria, and you want cover for this, you must
                complete a medical assessment to assess whether we can accept
                this risk and what, if any additional premium is payable by you,
                to have that condition specified on your policy.
              </p>
              <p>
                <strong
                  >Please also read the “General Exclusions” section of the PDS.
                  which can be viewed
                </strong>
                <a
                  class="link"
                  href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                  ><strong
                    ><span style="text-decoration: underline"
                      >here.</span
                    ></strong
                  ></a
                >
              </p>
            </ul>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd8" style="" />
          <label for="rd8" class="tab__label"
            >I have a condition that is automatically covered and I have
            recently visited my doctor so I’m not sure what you mean by
            treatment. What do you consider treatment in this case?</label
          >
          <div class="tab__content">
            <p>
              If you have a condition that is on the list of medical conditions
              we automatically cover and you meet the criteria for the
              condition, we do not consider 'treatment' to include things such
              as an annual or routine check-up with your medical practitioner,
              blood testing or a visit to a medical practitioner to obtain a
              regular prescription.
            </p>
            <p>
              Treatment is therefore anything that falls outside of these things
              or where there has been a deterioration in your condition or a
              requirement for any further investigation.
            </p>
            <p>
              Please refer to any specific criteria for coverage for an
              automatically covered condition and ensure you meet all of these
              to obtain cover.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd9" style="" />
          <label for="rd9" class="tab__label"
            >I have a Medical Condition that isn't automatically covered. Can I
            still get cover?</label
          >
          <div class="tab__content">
            <p>
              If <i><strong>You</strong></i> have a
              <i><strong>Medical Condition</strong></i> that is not
              automatically covered above and <i><strong>You</strong></i> want
              cover for this <i><strong>Medical Condition, You</strong></i> will
              need to complete <i><strong>Our</strong></i> online medical
              assessment so that <i><strong>We</strong></i> can assess whether:
            </p>
            <ul>
              <li>
                <i><strong>We</strong></i> can cover the
                <i><strong>Medical Condition</strong></i> – in which case
                additional premium may be payable and the
                <i><strong>Medical Condition</strong></i> will be listed on
                <i><strong>Your Certificate of Insurance</strong></i
                >; or
              </li>
              <li>
                <i><strong>We</strong></i> can’t cover the
                <i><strong>Medical Condition</strong></i> – in which case, the
                <i><strong>Medical Condition</strong></i> that has not been
                accepted will be listed on
                <i><strong>Your Certificate of Insurance</strong></i
                >.
              </li>
            </ul>
            <p>
              <strong
                >Please also read the General Exclusions, which apply in
                addition to any limitations set out above.</strong
              >
            </p>
            <p>
              You can complete the online medical assessment as part of your
              travel insurance quote at
              <a class="link" href="https://www.ract.com.au/travel-insurance"
                ><span style="text-decoration: underline"
                  ><strong>www.ract.com.au/travel-insurance</strong></span
                ></a
              >.
            </p>
            <p>
              This is an online set of medical questions (assessment) which you
              will need to complete if you wish to apply for travel insurance
              but have Medical Conditions which are not automatically covered
              under the policy. Upon completion of this assessment we will
              inform you if the Medical Condition can be covered and any
              additional premium that would be applicable.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd10" style="" />
          <label for="rd10" class="tab__label"
            >Am I covered if I'm pregnant?</label
          >
          <div class="tab__content">
            <p>
              <i><strong>Our</strong></i> policies provide cover for pregnancy
              in limited circumstances.
            </p>
            <p><strong>What is covered</strong></p>
            <p>
              Cover is included automatically up to the end of the 25th week of
              pregnancy for:
            </p>
            <ol style="list-style-type: lower-alpha">
              <li>single non-complicated pregnancies;</li>
              <li>unexpected pregnancy complications; and</li>
              <li>
                childbirth which was accelerated by accidental injury in limited
                circumstances.
              </li>
            </ol>
            <p>Please see further information below.</p>
            <p>
              <i><strong>You</strong></i> will need to apply for cover if at the
              <i><strong>Relevant Time, You</strong></i> know
              <i><strong>You</strong></i> are pregnant and are aware of, or a
              reasonable person in the circumstances could be expected to have
              been aware of, any of the following:
            </p>
            <ol type="i">
              <li>
                there have been complications with this or a previous pregnancy,
              </li>
              <li>
                <i><strong>You</strong></i> are expecting a multiple pregnancy
                (such as triplets or twins), or
              </li>
              <li>
                <i><strong>You</strong></i> have a
                <i><strong>Medical Condition</strong></i> which could have an
                adverse impact on <i><strong>Your</strong></i> health.
              </li>
            </ol>
            <p>
              Pregnancy complications are considered
              <i><strong>Medical Conditions</strong></i> and need to be
              disclosed and assessed if <i><strong>You</strong></i> want cover
              for these conditions whilst on <i><strong>Your Trip</strong></i
              >. Pregnancy complications include those that occur during
              pregnancy or may be caused by
              <i><strong>Medical Conditions</strong></i> that already existed
              prior to the pregnancy, such as previous high risk of miscarriage,
              gestational diabetes, hyperemesis (severe morning sickness) or
              pre-eclampsia. Please refer to the
              <i>Medical Conditions We need to assess</i> section above.
            </p>
            <p><strong>What is not covered</strong></p>
            <p>There is no cover for:</p>
            <ol style="list-style-type: lower-alpha">
              <li>
                pregnancy complications occurring from the 26th week of
                gestation or as described in the section above
                <i><strong>(Pregnancy Complications)</strong></i> unless such
                complications are specifically accepted by Us and noted on your
                Certificate of Insurance;
              </li>
              <li>
                childbirth at any stage of the pregnancy, other than as a result
                of an accident occurring prior to the end of the 25th week of
                <i><strong>Your</strong></i> pregnancy which causes
                <i><strong>You</strong></i> to give birth prematurely;
              </li>
              <li>
                the health or care of a newborn child, irrespective of the stage
                of pregnancy when the child is born; or
              </li>
              <li>regular antenatal care.</li>
            </ol>
            <p>
              <i><strong>We</strong></i> recommend that
              <i><strong>You</strong></i> contact
              <i><strong>Your Treating Doctor</strong></i> and obtain written
              confirmation that <i><strong>You</strong></i> are fit to travel
              before commencing <i><strong>Your</strong></i> planned
              <i><strong>Trip</strong></i
              >. Please see the <i>Changes in Your health</i> section above,
              which outlines conditions to <i><strong>Our</strong></i> cover.
            </p>
            <p>
              If <i><strong>You</strong></i> are unsure whether
              <i><strong>You</strong></i> need to complete a medical assessment
              for <i><strong>Your</strong></i> pregnancy please call 1300 406
              752.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd11" style="" />
          <label for="rd11" class="tab__label">
            What happens if I develop a medical condition as defined 
            in the PDS after I buy travel insurance but before I leave 
            on my trip. Would I still be covered?
          </label>
          <div class="tab__content">
            <p>
              If <strong><i>You</i></strong> become aware of a 
              change in your health before you start <strong><i>
              Your Trip, You</i></strong> will need to advise 
              <strong><i>Us</i></strong> prior to starting 
              <strong><i>Your Trip</i></strong> or you may not be 
              covered for that new or changed condition.
            </p>
            <p>
              <strong><i>We</i></strong> will assess the change in 
              <strong><i>Your</i></strong> health to determine 
              <strong><i>if We can</i></strong> offer 
              <strong><i>You</i></strong> cover for this condition 
              and on what terms.
            </p>
            <p>
              If <strong><i>We</i></strong> consider the change in 
              <strong><i>Your</i></strong> health no longer meets our 
              risk criteria, <strong><i>We</i></strong> will advise you 
              of this decision to decline or withdraw cover and issue 
              <strong><i>You</i></strong> with an updated 
              <strong><i>Certificate of Insurance.</i></strong>
            </p>
            <p>
              If <strong><i>We</i></strong> withdraw or decline cover 
              and <strong><i>You</i></strong> decide to cancel 
              <strong><i>Your Trip</i></strong> as a result, cover is 
              available under ‘Section 1 Cancellation Fees and Lost 
              Deposits’. If <strong><i>You</i></strong> do not claim 
              and want to alternatively cancel <strong><i>Your</i></strong> 
              policy prior to starting <strong><i>Your Trip</i></strong> as 
              a result of this decision, <strong><i>We</i></strong> will 
              refund <strong><i>Your</i></strong> premium in full, even 
              if this is outside the cooling off period.
            </p>
            <p>
              It is important that <strong><i>You</i></strong> advise us 
              of any change in <strong><i>Your</i></strong> health so we 
              can assess if we can continue to cover 
              <strong><i>You</i></strong> for these changes.
            </p>
            <p>
              If <strong><i>You</i></strong> do not tell <strong><i>Us</i></strong> 
              about a change in <strong><i>Your</i></strong> health, 
              <strong><i>You</i></strong> may not be covered if You suffer a 
              loss because of that condition.
            </p>
            <p>
              <strong>
                Tell us about changes to existing conditions - we 
                may still be able to cover you
              </strong>
            </p>
            <p>
              John purchased a policy for his trip to Bali 3 months prior to 
              his departure date and was automatically covered for his epilepsy 
              condition because there had been no change to his medication for 
              over 2 years and he was currently only prescribed one anticonvulsant 
              medication.
            </p>
            <p>
              Over the next 2 months, John unfortunately experienced seizures. 
              His neurologist advised John to change his medication and add a 
              second anticonvulsant to his daily medication.
            </p>
            <p>
              As John’s medical condition had changed, he needed to advise us 
              and complete a medical assessment so we could determine if we 
              could continue to cover him for this condition during his trip 
              and, if so, to calculate any additional premium payable.
            </p>
            <p>
              John contacted us, completed our assessment and was provided cover 
              for his epilepsy under the policy with payment of an additional premium.
            </p>
            <p>
              <strong>
                We can't cover all changes to existing conditions - you may 
                still be covered for cancellation costs or eligible for a full refund
              </strong>
            </p>
            <p>
              Jane purchased a policy for her trip to the USA 6 months prior to her 
              departure date and declared she was receiving counselling for anxiety. 
              After completing our online medical assessment, we agreed to cover Jane 
              for her anxiety for an additional premium.
            </p>
            <p>
              Three months before her trip, Jane experienced an anxiety episode that 
              required her to be hospitalised for an extended period and placed on 
              medication. Jane advised us of this change in her  medical condition 
              and completed our medical assessment.
            </p>
            <p>
              Unfortunately, based on our assessment, we were no longer able to offer 
              to provide cover to Jane for her anxiety condition during her trip. 
              Jane decided to cancel her trip. She could have made a claim for 
              cancellation costs but she was able to get a full refund from her travel 
              agent, so she asked us to cancel her policy and, because she hadn't made 
              any claims, we gave her a full refund.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd12" style="" />
          <label for="rd12" class="tab__label"
            >Will my children be covered by my insurance?</label
          >
          <div class="tab__content">
            <p>
              Your Dependents such as children, grandchildren, step-children and
              foster children are covered at no extra cost if they have not
              disclosed a medical conditon and if they meet the following
              criteria:
            </p>
            <ul>
              <li>aged 25 years or younger at the time You buy Your policy;</li>
              <li>
                financially dependent on their parents or grandparents and not
                working full time;
              </li>
              <li>travelling with You for Your entire Trip;</li>
              <li>
                listed on the Certificate of Insurance as Your Dependant; and
              </li>
              <li>
                whilst on Your Trip, is dependent on an Adult listed on Your
                Certificate of Insurance.
              </li>
            </ul>
            <p>
              If they meet the above criteria and you choose our Ski and Winter
              Sports option or Cruise option, they’ll be covered for this as
              well. If you have omitted to list them, please contact us as soon
              as possible.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd13" style="" />
          <label for="rd13" class="tab__label">
            What policy benefits and limits are applicable for my children and
            grandchildren?
          </label>
          <div class="tab__content">
            <p>
              Your Dependents such as children, grandchildren, grandchildren,
              step-children and foster children will be covered at no extra cost
              if they have not disclosed a medical condition and they meet the
              following criteria:
            </p>
            <ul>
              <li>aged 25 years or younger at the time You buy Your policy;</li>
              <li>
                financially dependent on their parents or grandparents and not
                working full time;
              </li>
              <li>travelling with You for Your entire Trip;</li>
              <li>
                listed on the Certificate of Insurance as Your Dependant; and
              </li>
              <li>
                whilst on Your Trip, is dependent on an Adult listed on Your
                Certificate of Insurance.
              </li>
            </ul>

            <p>
              The limit for any benefit payable Dependents is included in the
              insured adult's sum insured. For example, if an adult has
              Comprehensive cover on a single policy and a claim is made under
              Section 13 Travel Delay, the maximum amount that may be paid for 1
              adult and their children combined is $3,000. Please note there are
              other sections of the policy whereby the limits are itemised per
              person which includes Dependents individually eg. Funeral expenses
              in section 2G. Please refer to the
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><span style="text-decoration: underline"
                  ><strong>Product Disclosure Statement</strong></span
                ></a
              >
              for further details.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd14" style="" />
          <label for="rd14" class="tab__label">
            Do I need to list all countries I will be travelling to when I
            purchase my policy?
          </label>
          <div class="tab__content">
            <p>
              Yes, you should list all countries to confirm that cover is
              available for the countries you are travelling to. You should also
              check
              <a class="link" href="http://smartraveller.gov.au"
                ><strong>www.smarttraveller.gov.au</strong></a
              >
              as the insurer does not provide cover for any country or region
              which is the subject of a “Do Not Travel“ advisory issued by DFAT
              prior to or after you purchasing your policy.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd15" style="" />
          <label for="rd15" class="tab__label"
            >Which policy do I select if I am going on a cruise?</label
          >
          <div class="tab__content">
            <p>
              Cover for international and domestic river cruising is included
              automatically in the policy and you do not need to select the
              Cruise option to be covered.
            </p>
            <p>
              If you are going on an ocean Cruise which stops at other
              countries, you will need to select International Cover and select
              each country where the cruise stops.
            </p>
            <p>
              All Australian waters and international ocean cruising will
              require you to select the Cruise option to be covered while you
              are on your cruise. An extra premium is payable for cruise cover.
            </p>

            <p>
              If you are going on an ocean cruise which is only in Australian
              waters or calling into ports in Australia and you still require a
              policy which includes medical cover whilst you are on board the
              ship as many on-board medical providers on cruises are not
              registered with Medicare (check with your cruise provider), you
              will need to select Australian Cruise as your destination. This
              will then allow the insurer to give you medical and evacuation
              cover whilst at sea but not if you go to a medical provider whilst
              in port in Australia. Please refer to the
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><span style="text-decoration: underline"
                  ><strong>Product Disclosure Statement</strong></span
                ></a
              >
              for further details.
            </p>
            <p>
              Please note the Domestic policy does not include any medical cover
              as the insurer is a general insurer and cannot cover medical costs
              in Australia. Therefore the Domestic policy is not suitable for
              cruises if you require cover for on board medical treatment and
              evacuation.
            </p>
            <p>An extra premium is payable for the Cruise cover option.</p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd16" style="" />
          <label for="rd16" class="tab__label"
            >Will I be covered for Covid-19?</label
          >
          <div class="tab__content">
            <p>
              Certain policies include limited benefits for COVID-19. For all
              other policies we provide, COVID-19 related losses are part of a
              General Exclusion and not intended to be covered. Please refer to
              the Product Disclosure Statement for the policy terms, limits
              conditions and exclusions relevant to this cover.
            </p>
            <p>
              Please see further FAQs to be read in conjunction with the PDS
              relating to cover for COVID-19.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd17" style="" />
          <label for="rd17" class="tab__label"
            >When do I receive my policy documents?</label
          >
          <div class="tab__content">
            <p>
              When you purchase your policy, your policy documents will be
              emailed to you. Your documents include your certificate of
              insurance and your
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><span style="text-decoration: underline"
                  ><strong
                    >RACT Travel Insurance Combined Financia</strong
                  ></span
                ><strong
                  ><span style="text-decoration: underline"
                    >l Services Guide and Product Disclosure Statement</span
                  ></strong
                ></a
              >
              It's a good idea to carry a copy of your policy documents with you
              when you travel. It is also a good idea to keep them in your email
              inbox so you can access them if you need to.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd18" style="" />
          <label for="rd18" class="tab__label"
            >When does my policy start?</label
          >
          <div class="tab__content">
            <p>
              Cover for cancellation, lost deposits and financial default
              commences from the date of purchase. All other benefits commence
              on the trip start date you have stated when purchasing your
              policy.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd19" style="" />
          <label for="rd19" class="tab__label"
            >I made a mistake when purchasing my policy, how do I fix it?</label
          >
          <div class="tab__content">
            <p>
              Please contact us on 02 8055 1673 as soon as you realise the error
              so that we can review and arrange for amendments where possible.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd20" style="" />
          <label for="rd20" class="tab__label"
            >What happens if I change my mind and want to cancel the
            policy?</label
          >
          <div class="tab__content">
            <p>
              You have 21 days from the day You purchase Your policy to decide
              if the cover is right for You and suits Your needs. If the policy
              does not meet Your needs You can cancel Your policy within the
              “cooling-off period” for a full refund, provided You:
            </p>
            <ul>
              <li>Haven’t started Your Trip; and/or</li>
              <li>Haven’t made a claim; and/or</li>
              <li>
                Don’t intend to make a claim or exercise any other rights under
                Your policy.
              </li>
            </ul>
            <p>
              Simply contact Us on 02 8055 1673 within the cooling-off period
              and We can arrange this for You.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd21" style="" />
          <label for="rd21" class="tab__label"
            >How do I extend my policy?</label
          >
          <div class="tab__content">
            <p>You can extend your policy under certain conditions.</p>
            <p>
              We will extend the term of Your cover for no additional cost if
              any delay is due to a reason which is covered under Your policy.
            </p>
            <p>
              For other reasons you can apply to extend your International or
              Domestic Single-Trip Policy by phoning us on 02 8055 1673 as soon
              as practicable and prior to your original policy expiry date.
              Extension of cover is subject to the insurer’s written approval
              and you will need to pay any applicable extra premium. If the
              insurer agrees to extend cover, you will be issued with a new
              certificate of insurance. The period of insurance on your new
              Certificate of Insurance, combined with your previous period of
              insurance, cannot be longer than a combined maximum period of 12
              months. Applications to extend cover are subject to additional
              conditions — please refer to the
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><strong
                  ><span style="text-decoration: underline"
                    >Product Disclosure Statement</span
                  ></strong
                ></a
              >
              for further details.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd22" style="" />
          <label for="rd22" class="tab__label">What is an excess?</label>
          <div class="tab__content">
            <p>
              If you make a claim you may be required to pay an excess. An
              excess is an agreed dollar amount that is subtracted from each and
              every Insured Event - see the definitions of 'Excess' and 'Insured
              Event' in the PDS for more information. The default International
              Single Trip policy excess is $250 but this can be reduced to $100
              or $0 for an increased premium or increased to $500 or $1,000 for
              a reduced premium at the time of purchase. The default for our
              Domestic Single Trip policy is $100 but can be reduced to $0 for
              an increased premium at the time of purchase. The default Annual
              Multi-Trip policy excess is $250 but this can be reduced to $100
              or $0 for an increased premium at the time of purchase. Your
              excess will be shown on your certificate of insurance. Please note
              IF you choose a higher excess it can reduce your premiums but it
              will affect the benefit you receive when you make a claim. Some
              cover benefit limits or sublimits may be less than your total
              excess and therefore the insurer may not contribute anything to
              your claim.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd23" style="" />
          <label for="rd23" class="tab__label"
            >Is there a maximum trip duration for an Annual Multi-Trip
            policy?</label
          >
          <div class="tab__content">
            <p>
              The maximum days allowed per trip is either 30, 45 or 60 days,
              depending on the policy you choose. You must select the
              appropriate number of days that will cover any single trip you may
              take during the 12 month period at the time of purchase. The days
              allowed per trip may be able to be increased to a longer duration
              during the period of the policy and an additional premium will
              apply. Please contact us for assistance.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd24" style="" />
          <label for="rd24" class="tab__label">
            Is there a limit to the amount of trips I can take on an Annual
            Multi-Trip policy?</label
          >
          <div class="tab__content">
            <p>
              You are covered for an unlimited number of trips over a 12 month
              period, provided that each trip is at least 250km from your home.
              The maximum days allowed per trip is either 30, 45 or 60 days,
              depending on the policy you choose.
            </p>
            <p>
              If You purchase the Annual Multi-Trip plan, the Sums Insured under
              each of the sections of the policy are automatically reinstated on
              the completion of each Trip.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd25" style="" />
          <label for="rd25" class="tab__label"
            >Does my Annual Multi-Trip policy cover me domestically?</label
          >
          <div class="tab__content">
            <p>
              Your Annual Multi-Trip policy covers you for international trips
              as well as trips within Australia where the trip is more than
              250km from your home. If your trip is in Australia, you are not
              covered for medical, hospital or dental expenses. Other exclusions
              may also apply. Please read the PDS for further information which
              can be viewed
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><strong
                  ><span style="text-decoration: underline">here</span></strong
                ></a
              >.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd26" style="" />
          <label for="rd26" class="tab__label">
            Are all benefits payable per policy or per insured adult on an
            Annual Multi-Trip policy?
          </label>
          <div class="tab__content">
            <p>
              Most of your policy benefits are per insured adult, however you
              should read the
              <a
                class="link"
                href="https://dmwp034i1ogee.cloudfront.net/prod/PDS/RACT/15122023_RACT_PDS.pdf"
                ><strong
                  ><span style="text-decoration: underline"
                    >Product Disclosure Statement</span
                  ></strong
                ></a
              >
              for further information. The limits are reinstated after each
              trip.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd27" style="" />
          <label for="rd27" class="tab__label">
            Can people with a joint Annual Multi-Trip policy travel alone for a
            period of time and still be covered?
          </label>
          <div class="tab__content">
            <p>
              Insured adults on the policy can travel alone on a trip.
              Dependents such as children, grandchildren, step-children and
              foster children who are covered under the policy must travel with
              an insured adult for the whole trip.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd28" style="" />
          <label for="rd28" class="tab__label"
            >What is the emergency phone number to call while I am
            travelling?</label
          >
          <div class="tab__content">
            <p>
              If you have an overseas medical emergency, our Emergency Medical
              Assistance team is available 24 hours a day, 365 days a year to
              take your call.
            </p>
            <p>61 2 8055 1627 (reverse charges from the overseas operator)</p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd29" style="" />
          <label for="rd29" class="tab__label"
            >Who do I contact if I get sick or injured while travelling
            overseas?</label
          >
          <div class="tab__content">
            <p>
              If something unexpected happens while You are overseas, We want to
              ensure We can help make it as stress free as possible. If You have
              an overseas medical emergency, contact Our assistance team
              immediately, 24 hours a day, 7 days a week on: +61 2 8055 1699
              (reverse charges accepted from the overseas operator).
            </p>
            <p>If You:</p>
            <ul>
              <li>are hospitalised; or</li>
              <li>
                You are being treated as an outpatient and the total cost of any
                consultation or treatment will exceed $2,000
              </li>
            </ul>
            <p>
              You, or a member of Your travelling party, should contact Us as
              soon as reasonably practicable. If You do not contact Us, and
              incur costs without Our consent, We may limit the amount payable
              under a claim, to the amount We would have paid towards any
              expenses (including medical) or for any evacuation/repatriation or
              airfares that have not been approved or arranged by Us.
            </p>
            <p>
              You will need to keep all your medical reports and receipts from
              the doctors and or hospital. These documents will be needed to
              support your claim when it is lodged.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd30" style="" />
          <label for="rd30" class="tab__label"
            >Do you cover medical expenses within Australia</label
          >
          <div class="tab__content">
            <p>
              No, our policies cover reasonable overseas medical costs only,
              which can be very high if you do not have travel insurance. There
              is no medical, hospital or dental cover on a Domestic policy. As a
              general insurer the insurer is unable to pay medical costs in
              Australia and this includes any gap payments on any Medicare or
              private health insurer items.
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="radio" name="accordion-2" id="rd31" style="" />
          <label for="rd31" class="tab__label">How do I make a claim?</label>
          <div class="tab__content">
            <p>
              Claims can be lodged either online at
              <a href="/how-to-claim" class="link"
                ><strong
                  ><span style="text-decoration: underline"
                    >how to claim</span
                  ></strong
                ></a
              >
              under the “how to claim“ tab, by phone, or by email. If lodging by
              phone or email the applicable claim form will be provided for
              completion together with details of the documentation that needs
              to be provided.
            </p>
            <p>
              Providing the information needed helps the insurer to make a
              timely and accurate decision about your claim. You can contact us
              either during your trip or once you have returned and we will
              guide you through the process. The insurer will not be able to
              process your claim unless you provide us with all of the necessary
              information. Full details should be submitted within 30 days of
              your return.
            </p>
            <p>
              For all claims, evidence of the medical condition treated,
              incident or loss must be supported by the relevant documentation
              e.g. police report, medical report, receipts, proof of ownership
              etc. If you cannot provide it, then the insurer may reduce or
              refuse to pay your claim.
            </p>
            <dl>
              <dt>Phone:</dt>
              <dd>1300 406 752</dd>
              <dt>Web:</dt>
              <dd>
                <a href="/how-to-claim" class="link"
                  ><strong
                    ><span style="text-decoration: underline"
                      >how to claim</span
                    ></strong
                  ></a
                >
              </dd>
              <dt>Email:</dt>
              <dd>
                <a
                  class="link"
                  href="/cdn-cgi/l/email-protection#acdecdcfd8cfc0cdc5c1dfecd8c1c2cacdd8decddac9c0c5c2dfd9decdc2cfc982cfc3c182cdd9"
                  ><strong
                    ><span style="text-decoration: underline"
                      ><span
                        class="__cf_email__"
                        data-cfemail="3745565443545b565e5a4477435a59515643455641525b5e59444245565954521954585a195642"
                        >[email&nbsp;protected]</span
                      ></span
                    ></strong
                  ></a
                >
              </dd>
              <dt>Mail:</dt>
              <dd>
                RACT Travel Insurance Claims
                <br />
                C/- TMNFA
                <br />
                GPO Box 4616
                <br />
                Sydney 2001
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
