<div class="card theme-search">
  <app-racv-common-header></app-racv-common-header>
  <section class="mainhero">
    <div class="container-fluid herooverlay"></div>
    <div class="container">
      <div class="herocontent row align-items-center text-center">
        <div class="col-md-12">
          <h1 class="display-5 fw-bold mt-10 herotitle text-light">
            {{ titleText }}
          </h1>
          <p class="h4 fw-light text-light mb-2 herosubs">
            {{ subtitleText }}
          </p>
        </div>
      </div>
      <app-country-search></app-country-search>
    </div>
  </section>
  <app-racv-footer></app-racv-footer>
</div>
