import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(
    private localCacheService: LocalCacheService,
    private titleService: Title,
  ) {}

  pageLoad() {
    this.safeExecute(() => {
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const pageInfo = {
        event: 'virtual_page_view',
        page_location: window.location.href,
        page_referrer: document.referrer,
        page_title: this.titleService.getTitle(),
        visitor_type: quoteDetail?.isClubMember ? 'member' : 'guest',
        member_id: quoteDetail?.membershipNumber ?? '',
      };
      this.sendGAEvent(pageInfo);
    });
  }

  sendViewItemListEventDetail() {
    // step 1 after page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const basicPrice = this.localCacheService.getSessionStorage('basicPrice');
    const topPrice = this.localCacheService.getSessionStorage('topPrice');
    const essentialPrice =
      this.localCacheService.getSessionStorage('essentialPrice');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'view_item_list',
        ecommerce: {
          item_list_id: quoteEnquiry.tripType,
          item_list_name: quoteEnquiry.tripType,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                topPrice.selectedCoverTitle
              }`,
              item_id: topPrice.selectedPrice.clubProductGuid,
              price:
                topPrice.selectedPrice
                  .totalPremium /*Dynamically populate actual price*/,
              coupon: quoteEnquiry.isClubMember
                ? 'member_discount'
                : 'no_member_discount' /*Dynaically update this if user said they are a member or not*/,
              discount: quoteEnquiry.isClubMember
                ? topPrice.selectedPrice.membershipDisc
                : 0 /*if it's a member and if they get a discount please indicate the discount*/,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: topPrice.selectedPrice.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: topPrice.selectedPrice.productAlias,
              item_list_id: topPrice.selectedPrice.productAlias,
              index: 1,
              quantity: '1',
            },
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                essentialPrice.selectedCoverTitle
              }`,
              item_id: essentialPrice.selectedPrice.clubProductGuid,
              price:
                essentialPrice.selectedPrice
                  .totalPremium /*Dynamically populate actual price*/,
              coupon: quoteEnquiry.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteEnquiry.isClubMember
                ? essentialPrice.selectedPrice.membershipDisc
                : 0 /*if it's a member and if they get a discount please indicate the discount*/,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: essentialPrice.selectedPrice.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: essentialPrice.selectedPrice.productAlias,
              item_list_id: essentialPrice.selectedPrice.productAlias,
              index: 2,
              quantity: '1',
            },
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                basicPrice.selectedCoverTitle
              }`,
              item_id: basicPrice.selectedPrice.clubProductGuid,
              price: basicPrice.selectedPrice.totalPremium,
              coupon: quoteEnquiry.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteEnquiry.isClubMember
                ? basicPrice.selectedPrice.membershipDisc
                : 0,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: basicPrice.selectedPrice.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: basicPrice.selectedPrice.productAlias,
              item_list_id: basicPrice.selectedPrice.productAlias,
              index: 3,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendSelectItemDetail() {
    // step 1 click proceed button
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const selectedCoverDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );
    this.safeExecute(() => {
      const eventDetails = {
        event: 'select_item',
        ecommerce: {
          item_list_id: quoteEnquiry.tripType,
          item_list_name: quoteEnquiry.tripType,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                selectedCoverDetail.selectedCoverTitle
              }`,
              item_id: selectedCoverDetail.selectedPrice.clubProductGuid,
              price: selectedCoverDetail.selectedPrice.totalPremium,
              coupon: quoteEnquiry.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteEnquiry.isClubMember
                ? selectedCoverDetail.selectedPrice.membershipDisc
                : 0,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: selectedCoverDetail.selectedPrice.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: selectedCoverDetail.selectedPrice.productAlias,
              item_list_id: selectedCoverDetail.selectedPrice.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendViewItemEventDetail() {
    //step 2 after page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'view_item',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendAddToCartEventDetail() {
    // step 2 click confirm button
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'add_to_cart',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendAddToWishlistEventDetail() {
    //save and email quote
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'add_to_wishlist',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendViewCartEventDetail() {
    // step 3 after page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'view_cart',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendBeginCheckoutEventDetail() {
    // step 3 confirm button click
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'begin_checkout',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendAddPaymentInfoEventDetail(paymentMethod: any) {
    //step 4 click confirm and payment
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'add_payment_info',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          payment_type: paymentMethod,
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          coupon: quoteDetail.isClubMember
            ? 'member_discount'
            : 'no_member_discount',
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendPurchaseEventDetail() {
    //step 5 after page load
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'purchase',
        members: {
          member: quoteEnquiry.isClubMember,
          member_id: quoteDetail.membershipNumber,
        },
        ecommerce: {
          currency: 'AUD',
          value: quoteDetail.premiums?.totalCost,
          tax: 0.0,
          shipping: 0.0,
          affiliation:
            'Online RAA Store / Specific store for click and collect',
          transaction_id: quoteDetail.quoteNumber,
          coupon: quoteDetail.promoCode,
          items: [
            {
              item_name: `${quoteDetail.isSingleTrip ? 'Single' : 'Multi'} - ${
                quoteDetail.productAlias
              }`,
              item_id: quoteDetail.clubProductGuid,
              price: quoteDetail.premiums?.totalCost,
              coupon: quoteDetail.isClubMember
                ? 'member_discount'
                : 'no_member_discount',
              discount: quoteDetail.premiums?.membershipDiscount,
              item_brand: 'RAA Travel Insurance' /*Static*/,
              item_category: 'Insurance NB' /*Static*/,
              item_category2: 'Travel' /*Static*/,
              item_category3: this.getDestinationsString(
                quoteDetail.destinations,
              ),
              item_category4: quoteDetail.isCruise
                ? 'Going on a cruise'
                : 'Not going on a cruise',
              item_list_name: quoteDetail.productAlias,
              item_list_id: quoteDetail.productAlias,
              index: 1,
              quantity: '1',
            },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  getDestinationsString(destinations: any) {
    let destinationString = '';
    destinations.forEach((destination: any, index: number) => {
      destinationString += destination.countryName;
      if (index < destinations.length - 1) {
        destinationString += ', ';
      }
    });
    return destinationString;
  }

  sendGAEvent(eventDetails: any) {
    if (environment.GASwitcher) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(eventDetails);
      console.log('GA Event: window.dataLayer new', window.dataLayer);
    }
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the Google analytics code:', error);
    }
  }
}
